<template>
  <div class="forget" >
    <div class="forger-left">
      <signInUpLeft />
    </div>
    <div class="forget-content" > 
      <div  class="step">
        <step :current="current"></step>
      </div>
      <div class="sign-up-content" v-if="!showSuccessModal">
        <a-form>
          <div >
            <div class="password-wrap">
              <a-form-item
                v-bind="validateInfos.password"
              >
                <div class="password-input">
                  <artmisads-input-password @blur="() => validate('password')" style="height: 40px;" v-model:value="modelRef.password" placeholder="New Password"/>
                </div>
              </a-form-item>
            </div>
            <div class="password-wrap">
              <a-form-item
                v-bind="validateInfos.confirmPassword"
              >
                <div class="password-input">
                  <artmisads-input-password @blur="() => validate('confirmPassword')" style="height: 40px;" v-model:value="modelRef.confirmPassword" placeholder="Conﬁrm Your Password" />
                </div>
              </a-form-item>
            </div>
          </div>
          <div class="sign-btn">
            <a-form-item>
              <artmisads-button @click="onSubmit" type="primary" html-type="submit">Reset Password</artmisads-button>
            </a-form-item>
          </div>
        </a-form>
      </div>
      <div class="reset-success" v-else>
        <div class="sucess-icon">
          <svg class="icon" aria-hidden="true">
            <use xlink:href="#icon-success-tianchong"></use>
          </svg>
        </div>
        <div class="redirect-tip">
          Redirecting to the login page in <span style="font-weight: 500;">{{ timeCount }}</span> seconds
        </div>
        <div class="success-btn">
          <artmisads-button type="primary" @click="goLogin">Sign in with your new password</artmisads-button>
        </div>
      </div>
    </div>
    <contextHolder />
  </div>
</template>
<script setup>
  import { onMounted, reactive, ref,onBeforeUnmount } from 'vue';
  import { useRoute,useRouter } from 'vue-router';
  import signInUpLeft from '../../components/signInUpLeft.vue';
  import { Form, message, notification } from 'ant-design-vue';
  import { signApi } from '../../server/index';
  import step from './step.vue';
  let intervalId;//定时器
  const current=ref(2);
  const timeCount = ref(10);
  const router=useRouter();
  const route=useRoute();
  const showSuccessModal = ref(false);
  const [api, contextHolder] = notification.useNotification();
  const openNotification = description => {
    api.info({
      message: `Warning`,
      description: description,
      placement: 'topRight',
      duration: 10
    });
  };

  const useForm = Form.useForm;

  const modelRef = reactive({
    password: '',
    confirmPassword: '',
  });

  const rulesRef = reactive({
    password: [{
      required: true,
      validator: validatePassword,
      trigger: ['blur']
    }],
    confirmPassword: [{
      required: true,
      validator: validateConfirmPassword,
      trigger: ['blur']
    }],
  });

  const { validate, validateInfos, resetFields } = useForm(modelRef, rulesRef);

  function validatePassword  (_rule, value) {
    if (value === '') {
      return Promise.reject('Password cannot be empty');
    } else if (value.length < 8 || !value.match(/[0-9]/) || !value.match(/[A-Z]/)) {
      return Promise.reject("Password must be at least 8 characters with 1 upper case letter and 1 number");
    } else {
      return Promise.resolve();
    }
  };

  function validateConfirmPassword (_rule, value) {
    if (value === '') {
      return Promise.reject('Please input the password again');
    } else if (value !== modelRef.password) {
      return Promise.reject("Passwords do not match");
    } else {
      return Promise.resolve();
    }
  };

  async function onSubmit () {
    const validateRes = await validate();
    if (!validateRes.errorFields) {
      let params={};
      params.newPassword=validateRes.password;
      params.token=route.query.token;
      const res = await signApi.resetPassword(params);
      const { error, success } = res;
        if (success) {
          showSuccessModal.value = true;
          current.value=3;
          localStorage.clear();
          deadline();
        } else {
          openNotification(error);
        }
    }
  }
  const deadline=()=>{
    const updateCounter=()=> {
      timeCount.value -= 1;
      // 当计数器到达 0 时，停止定时器
      if (timeCount.value <= 0) {
        goLogin();
        clearInterval(intervalId); // 停止定时器
        console.log('Countdown finished!');
      }
    }
    intervalId = setInterval(updateCounter, 1000);

  }
  
  const goLogin=()=>{
    router.push({name:'sign-in'})
  }
  onBeforeUnmount(()=>{
    clearInterval(intervalId);
  })

</script>
<style lang="less" scoped>

@media screen and (min-width: 960px) {
  .forget {
    width: 100vw;
    height: 100dvh;
    min-height: 700px;
    overflow: hidden;
    display: flex;
    background: #F4F2FA;
    .warning {
      position: fixed;
      top: 8px;
      right: 8px;
    }
  }
  .forget-content {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 120px;
    // justify-content: center;
    flex: 1;
    .step:deep{
      width: 500px;
      margin-bottom: 64px;
      .ant-steps-item-finish{
        .ant-steps-item-icon{
          background-color:#7D58E5;
          .ant-steps-finish-icon{
            color: #FFFFFF;
          }
        }
      }
      .ant-steps-item-title{
        white-space: nowrap;
      }
    }
  }
  .sign-up-content{
    display: flex;
    flex-direction: column;
    align-self: center;
    width: 400px;
    .password-wrap {
      width: 400px;
      font-family: Sora-Regular;
    }
    .password-input {
      width: 400px;
      display: flex;
      margin-top: 12px;
      flex-direction: column;
      font-family: Sora-Regular;

      
    }
    .sign-btn {
      width: 400px;
      margin-top: 40px;
      button {
        width: 100%;
        height: 40px;
        box-shadow: 0 1px 0 0 #1a16250d;
        border-radius: 20px;
        font-family: Sora-Medium;
        font-weight: 500;
        font-size: 15px;
        color: #FFFFFF;
        letter-spacing: 0.4px;
        text-align: center;
      }
    }
    :deep(.ant-form) {
      .ant-form-item {
        margin-bottom: 0;
      }
    }
  }
  .reset-success{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    .sucess-icon{
      padding: 4px;
      background-color:#D7E9E4 ;
      border-radius: 50%;
      .icon{
        font-size: 51px;
        fill: #2AAE67;
      }
    }
    .redirect-tip{
      margin: 12px 0 24px;
      font-family: Sora-Regular;
      font-size: 16px;
      color: var(--dark-2);
      letter-spacing: 0.4px;
      line-height: 22px;
    }
    .success-btn {
      width: 400px;
      button {
        width: 100%;
        height: 40px;
        font-family: Sora-Medium;
        font-weight: 500;
        font-size: 16px;
        color: #FFFFFF;
        letter-spacing: 0.4px;
        text-align: center;
      }
    }
  }
}

@media screen and (max-width: 479px) {
  .forget {
    width: 100vw;
    height: 100dvh;
    min-height: 700px;
    overflow: hidden;
    display: flex;
    background: #F4F2FA;
    justify-content: center;
    .warning {
      position: fixed;
      top: 8px;
      right: 8px;
    }
  }
  .forget-content {
    align-self: center;
    position: relative;
    z-index: 1;
    width: calc(100dvw - 32px);
    padding: 24px;
    width: 343px;
    background: #FFFFFF;
    box-shadow: 0 1px 0 0 #1a16250d;
    border-radius: 16px;
    .step:deep{
      width: 500px;
      margin-bottom: 16px;
      .ant-steps-item-finish{
        .ant-steps-item-icon{
          background-color:#7D58E5;
          .ant-steps-finish-icon{
            color: #FFFFFF;
          }
        }
      }
      .ant-steps-item-title{
        white-space: nowrap;
      }
    }
  }
  .sign-up-content{
    display: flex;
    flex-direction: column;
    align-self: center;
    width: 400px;
    .password-wrap {
      width: 300px;
    }
    .password-input {
      width: 300px;
      display: flex;
      margin-top: 24px;
      flex-direction: column;
      
    }
    .sign-btn {
      width: 300px;
      margin-top: 30px;
      button {
        width: 100%;
        height: 40px;
        box-shadow: 0 1px 0 0 #1a16250d;
        border-radius: 20px;
        font-family: Sora-Medium;
        font-weight: 500;
        font-size: 15px;
        color: #FFFFFF;
        letter-spacing: 0.4px;
        text-align: center;
      }
    }
    :deep(.ant-form) {
      .ant-form-item {
        margin-bottom: 0;
      }
    }
  }
  .reset-success{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    .redirect-tip{
      width: 300px;
      font-size: 20px;
      text-align: center;
      opacity: .7;
      margin-top: 24px;
    }
    .success-btn {
      width: 300px;
      margin-top: 24px;
      button {
        width: 100%;
        height: 40px;
        background: #7D58E5;
        box-shadow: 0 1px 0 0 #1a16250d;
        border-radius: 20px;
        font-family: Sora-Medium;
        font-weight: 500;
        font-size: 15px;
        color: #FFFFFF;
        letter-spacing: 0.4px;
        text-align: center;
      }
    }
  }
}

  
  
</style>